export const cardData = [
  {
    id: 1,
    name: "Fated Judge",
    race: "b",
    rarity: "e",
    price: 26,
    url: "/images/card/card_01.png",
    created_at: "2022-05-04",
  },
  {
    id: 2,
    name: "Son of the Wind",
    race: "a",
    rarity: "e",
    price: 10,
    url: "/images/card/card_02.png",
    created_at: "2022-04-04",
  },
  {
    id: 3,
    name: "Queen of the Night",
    race: "l",
    rarity: "e",
    price: 12,
    url: "/images/card/card_03.png",
    created_at: "2022-03-04",
  },
  {
    id: 4,
    name: "Devastator",
    race: "b",
    rarity: "e",
    price: 15,
    url: "/images/card/card_04.png",
    created_at: "2022-02-04",
  },
  {
    id: 5,
    name: "Storm Crow",
    race: "b",
    rarity: "sr",
    price: 17,
    url: "/images/card/card_05.png",
    created_at: "2022-05-12",
  },
  {
    id: 6,
    name: "Director",
    race: "a",
    rarity: "sr",
    price: 19,
    url: "/images/card/card_06.png",
    created_at: "2022-05-13",
  },
  {
    id: 7,
    name: "Night Watcher",
    race: "l",
    rarity: "sr",
    price: 100,
    url: "/images/card/card_07.png",
    created_at: "2022-05-24",
  },
  {
    id: 8,
    name: "Silver Knight",
    race: "b",
    rarity: "sr",
    price: 110,
    url: "/images/card/card_08.png",
    created_at: "2021-05-04",
  },
  {
    id: 9,
    name: "God of Death",
    race: "b",
    rarity: "sr",
    price: 502,
    url: "/images/card/card_09.png",
    created_at: "2020-05-04",
  },
  {
    id: 10,
    name: "Demon Hunter",
    race: "a",
    rarity: "sr",
    price: 200,
    url: "/images/card/card_10.png",
    created_at: "2022-10-04",
  },
  {
    id: 11,
    name: "Whisper of Dawn",
    race: "l",
    rarity: "sr",
    price: 210,
    url: "/images/card/card_11.png",
    created_at: "2022-10-05",
  },
  {
    id: 12,
    name: "Forest Guardian",
    race: "b",
    rarity: "sr",
    price: 45,
    url: "/images/card/card_12.png",
    created_at: "2022-10-08",
  },
  {
    id: 13,
    name: "Wind Warrior",
    race: "b",
    rarity: "r",
    price: 66,
    url: "/images/card/card_13.png",
    created_at: "2022-10-10",
  },
  {
    id: 14,
    name: "High Ranking Knight",
    race: "a",
    rarity: "r",
    price: 77,
    url: "/images/card/card_14.png",
    created_at: "2022-10-11",
  },
  {
    id: 15,
    name: "Swordsman",
    race: "l",
    rarity: "r",
    price: 88,
    url: "/images/card/card_15.png",
    created_at: "2022-10-12",
  },
  {
    id: 16,
    name: "Water Purification",
    race: "b",
    rarity: "r",
    price: 99,
    url: "/images/card/card_16.png",
    created_at: "2022-10-13",
  },
  {
    id: 17,
    name: "Assassin",
    race: "b",
    rarity: "r",
    price: 110,
    url: "/images/card/card_17.png",
    created_at: "2022-10-14",
  },
  {
    id: 18,
    name: "Wizard",
    race: "a",
    rarity: "r",
    price: 130,
    url: "/images/card/card_18.png",
    created_at: "2022-10-15",
  },
  {
    id: 19,
    name: "Water Spirit",
    race: "l",
    rarity: "r",
    price: 150,
    url: "/images/card/card_19.png",
    created_at: "2022-10-16",
  },
  {
    id: 20,
    name: "Sanctuary Guardian",
    race: "b",
    rarity: "r",
    price: 170,
    url: "/images/card/card_20.png",
    created_at: "2022-10-17",
  },
  {
    id: 21,
    name: "Hunter",
    race: "b",
    rarity: "r",
    price: 200,
    url: "/images/card/card_21.png",
    created_at: "2022-10-18",
  },
  {
    id: 22,
    name: "Pioneer",
    race: "a",
    rarity: "r",
    price: 456,
    url: "/images/card/card_22.png",
    created_at: "2022-10-19",
  },
  {
    id: 23,
    name: "Messenger",
    race: "l",
    rarity: "n",
    price: 765,
    url: "/images/card/card_23.png",
    created_at: "2022-11-01",
  },
  {
    id: 24,
    name: "Scout",
    race: "b",
    rarity: "n",
    price: 321,
    url: "/images/card/card_24.png",
    created_at: "2022-11-02",
  },
  {
    id: 25,
    name: "Lookout",
    race: "l",
    rarity: "n",
    price: 987,
    url: "/images/card/card_25.png",
    created_at: "2022-11-03",
  },
  {
    id: 26,
    name: "Spearhead",
    race: "b",
    rarity: "n",
    price: 675,
    url: "/images/card/card_26.png",
    created_at: "2022-11-04",
  },
  {
    id: 27,
    name: "Forest Spirit",
    race: "l",
    rarity: "n",
    price: 387,
    url: "/images/card/card_27.png",
    created_at: "2022-11-05",
  },
  {
    id: 28,
    name: "Observer",
    race: "b",
    rarity: "n",
    price: 398,
    url: "/images/card/card_28.png",
    created_at: "2021-11-01",
  },
  {
    id: 29,
    name: "Guardian",
    race: "l",
    rarity: "n",
    price: 778,
    url: "/images/card/card_29.png",
    created_at: "2021-11-02",
  },
  {
    id: 30,
    name: "Watcher",
    race: "b",
    rarity: "n",
    price: 992,
    url: "/images/card/card_30.png",
    created_at: "2021-11-03",
  },
  {
    id: 31,
    name: "Overseer",
    race: "l",
    rarity: "n",
    price: 336,
    url: "/images/card/card_31.png",
    created_at: "2021-11-04",
  },
  {
    id: 32,
    name: "Rogue",
    race: "b",
    rarity: "n",
    price: 663,
    url: "/images/card/card_32.png",
    created_at: "2021-11-05",
  },
];
